<!-- src/views/assemblies/AssembliesModalValues.vue -->
<template>
  <v-card-text>
    <div class="wrapper">
      <div class="wrapper-header"><h4>Attributes</h4></div>
      <v-col class="d-flex align-center">
        <v-autocomplete
          label="Select Attribute"
          :items="availableAttributesTrimmed"
          v-model="selectedAttribute"
          variant="underlined"
          data-cy="select-attribute"
          clearable
          style="width: 200px"
          item-title="name"
          return-object
          :menu-props="{ maxHeight: '250px' }"
        ></v-autocomplete>

        <v-btn class="btn-orange ml-2" @click="addAttribute" data-cy="add-attributes-btn">
          <v-icon left>mdi-plus</v-icon>
          Add
        </v-btn>
      </v-col>

      <v-card-text>
        <v-table density="compact">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="min-width: 130px">Name</th>
                <th>Value</th>
                <th>UoM</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(attr, index) in itemAttributes?.attributeValues" :key="index">
                <td>{{ attr.description }}</td>

                <td>
                  <div>
                    <template v-if="findAvailableAttributeById(attr.attributeId)?.values?.length">
                      <v-select
                        v-model="attr.value"
                        :items="findAvailableAttributeById(attr.attributeId)?.values"
                        variant="underlined"
                        item-title="value"
                        item-value="value"
                        data-cy="enum-select"
                      ></v-select>
                    </template>

                    <template v-else>
                      <v-text-field v-model="attr.value" variant="underlined" data-cy="enum-description-input"></v-text-field>
                    </template>
                  </div>
                </td>

                <td>{{ attr.uom }}</td>

                <td>
                  <span @click="removeAttribute(index)">
                    <i class="fa-duotone fa-solid fa-trash"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-card-text>
    </div>
  </v-card-text>
</template>

<script lang="ts">
  import { remove } from 'lodash';
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      itemAttributes: {
        type: Object as () => any,
        required: true,
      },
      availableAttributes: {
        type: Array as () => any,
        required: true,
      },
      modelValue: {
        type: Object as () => any,
        required: false,
      },
    },
    data() {
      return {
        selectedAttribute: null as any | null,
      };
    },
    computed: {
      availableAttributesTrimmed() {
        return this.availableAttributes.map((component: any) => ({
          ...component,
          name: component.name.length > 25 ? component.name.substring(0, 25) + '...' : component.name,
        }));
      },
    },
    methods: {
      addAttribute() {
        if (!this.selectedAttribute) return;

        const attribute = {
          id: Math.random().toString(36).substring(7),
          attributeId: this.selectedAttribute.id,
          description: this.selectedAttribute.name,
          uom: this.selectedAttribute.unit,
          value: null,
        };
        this.selectedAttribute = null;

        this.itemAttributes.attributeValues.push(attribute);
      },

      removeAttribute(index: number) {
        remove(this.itemAttributes.attributeValues, (attr: any, i: number) => i === index);
      },

      findAvailableAttributeById(attributeId: string) {
        return this.availableAttributes.find((attr: any) => attr.id === attributeId);
      },
    },
  });
</script>

<style scoped>
  .wrapper {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0px;
    margin-top: 10px;
  }
  .wrapper-header {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .wrapper-content {
    padding: 10px;
  }

  .custom-title {
    cursor: pointer;
    font-weight: bold;
  }
  .fa-trash {
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }

  .disabled-btn {
    cursor: not-allowed;
    color: #ccc;
    pointer-events: none;
  }
</style>
