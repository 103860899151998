<template>
  <v-row data-cy="tab-content-bom">
    <v-col cols="12" md="12" v-if="viewBom && viewBom.length"></v-col>

    <v-col cols="12" md="12" style="padding: 0px 12px">
      <div v-if="viewBom && viewBom.length">
        <div class="top-line">
          <h2>BoM Lines:</h2>
          <v-btn class="btn-orange" @click="addNewComponent" data-cy="add-new-item">Add New Component</v-btn>
        </div>

        <v-data-table :headers="headers" :items="viewBom" :items-per-page="-1" class="elevation-1" density="compact">
          <template #item.materials="{ item }">
            <n-select
              v-model:value="item.selectedMaterial"
              :options="item.materials"
              label-field="pn"
              value-field="pn"
              placeholder="Select Material"
              @update:value="reorderMaterials(item)"
              :render-option="renderOption"
            >
              <template #action>
                <div style="display: flex; align-items: center; justify-content: space-between; padding: 8px">
                  <v-btn class="btn-orange" size="small" @click="openAddMaterialModal(item)" data-cy="add-new-item">Add Material</v-btn>
                </div>
              </template>
            </n-select>
          </template>

          <template #item.cutSize="{ item }">
            <span v-if="item.uom === 'Each' && item.cutSize == 0"></span>
            <n-input-number v-else v-model:value="item.cutSize" placeholder="" :min="0" :max="9999" />
          </template>

          <template #item.cutUom="{ item }">
            <span>{{ item.cutUom }}</span>
          </template>

          <template #item.cutQuantity="{ item }">
            <span v-if="item.uom === 'Each' && item.cutQuantity == 0"></span>
            <n-input-number v-else v-model:value="item.cutQuantity" placeholder="" :min="0" :max="9999" />
          </template>

          <template #item.quantity="{ item }">
            <n-input-number v-model:value="item.quantity" placeholder="" :min="0" :max="9999" />
          </template>

          <template #item.uom="{ item }">
            <span>{{ item.uom }}</span>
          </template>

          <template #item.consumedOperation="{ item }">
            <n-select v-model:value="item.consumedOperation" :options="consumedOperationOptions" placeholder="" clearable filterable />
            <!-- <v-select v-model="item.consumedOperation" :items="['Cut Fiber Cable', '...']" label="" variant="underlined"></v-select> -->
          </template>

          <template #item.action="{ item }">
            <span @click="showDeleteDialog(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
          </template>
        </v-data-table>
      </div>

      <div v-else class="text-center no-data" data-cy="details-content">
        <div v-if="!loadingData">
          <h2 data-cy="details-title">No Product Data</h2>
          <p data-cy="details-description">
            Please enter a
            <strong>PN</strong>
            and press the
            <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
            button.
          </p>
        </div>
        <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
      </div>
    </v-col>
    <!-- Add Material Modal -->
    <n-modal v-model:show="isAddMaterialModalOpen" title="Add New Material" size="small" preset="card" style="width: 600px">
      <template #header>
        <div>Add New Material</div>
      </template>

      <div style="margin: 16px 0">
        <n-select
          v-model:value="selectedMaterial"
          :options="odooMaterialsOptions"
          label-field="pn"
          value-field="pn"
          placeholder="Search and Select Material (min 3 characters)"
          clearable
          filterable
          remote
          :loading="loadingMaterials"
          @search="handleSearchMaterial"
          :render-option="renderOption"
        />
      </div>

      <template #action>
        <v-btn block class="btn-orange" @click="confirmAddMaterial" data-cy="add-new-item">Add Material</v-btn>
      </template>
    </n-modal>
  </v-row>
  <Dialogs :deleteDialog="deleteDialog" @confirmDeleteItem="confirmDeleteItem" @closeDeleteDialog="closeDeleteDialog" />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import Dialogs from '@/components/Dialogs.vue';
  import etcService from '@/services/etcService';

  import type { VNode } from 'vue';
  import { h, ref } from 'vue';
  import type { SelectOption } from 'naive-ui';
  import { NTooltip } from 'naive-ui';

  interface Material {
    pn: string;
    odoo_id: number;
    description: string;
  }

  interface BomItem {
    materials: Material[];
    selectedMaterial: string;
    cutSize: number;
    cutUom: string;
    cutQuantity: number;
    quantity: number;
    uom: string;
    consumedOperation: string;
  }

  export default defineComponent({
    name: 'GenerateViewBom',
    components: {
      Dialogs,
    },
    props: {
      viewBom: {
        type: Array as PropType<BomItem[]>,
        required: false,
        default: () => [],
      },
      product_info: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      generation_item: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      loadingData: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        headers: [
          { title: 'Component', value: 'materials', sortable: false, width: '25%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '10%' },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '15%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '10%' },
          { title: 'Quantity', value: 'quantity', sortable: false, width: '10%' },
          { title: 'UOM', value: 'uom', sortable: false, width: '15%' },
          { title: 'Consumed In', value: 'consumedOperation', sortable: false, width: '15%' },
          { title: '', value: 'action', sortable: false, width: '2%' },
        ],
        consumedOperationOptions: [
          { label: 'Cut Fiber Cable', value: 'Cut Fiber Cable' },
          { label: 'Other Operation', value: 'Other Operation' },
        ],
        deleteDialog: false,
        itemToDelete: null as BomItem | null,
        // RAW MATERIALS TEST
        isAddMaterialModalOpen: false,
        selectedMaterial: '',
        loadingMaterials: false,
        currentBomItem: null as BomItem | null,
        odooMaterialsOptions: [] as Material[],
      };
    },
    mounted() {
      this.initializeMaterialSelection();
    },

    methods: {
      handleSearch() {
        console.log('searching');
      },
      renderOption: ({ node, option }: { node: VNode; option: SelectOption }) =>
        h(NTooltip, null, {
          trigger: () => node,
          default: () => `${option.description}`,
        }),
      initializeMaterialSelection() {
        this.viewBom.forEach((item) => {
          if (item.materials.length > 0) {
            item.selectedMaterial = item.materials[0].pn;
          } else {
            item.selectedMaterial = '';
          }
        });
      },
      addNewComponent() {
        this.viewBom.push({
          // materials: [{ pn: '', odoo_id: 0, description: '' }],
          materials: [],
          selectedMaterial: '',
          cutSize: 0,
          cutUom: '',
          cutQuantity: 0,
          quantity: 0,
          uom: '',
          consumedOperation: '',
        });
      },
      showDeleteDialog(item: BomItem) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },
      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },
      confirmDeleteItem() {
        if (this.itemToDelete) {
          const index = this.viewBom.indexOf(this.itemToDelete);
          if (index > -1) {
            this.viewBom.splice(index, 1);
          }
          this.closeDeleteDialog();
        }
      },
      reorderMaterials(item: BomItem) {
        const selected = item.selectedMaterial;
        const index = item.materials.findIndex((material) => material.pn === selected);
        if (index > -1) {
          const [selectedMaterial] = item.materials.splice(index, 1);
          item.materials.unshift(selectedMaterial);
        }
      },

      addNewMaterial(item: any) {
        console.log('add new material', item);
      },

      // RAW MATERIALS TEST
      openAddMaterialModal(item: BomItem) {
        this.selectedMaterial = '';
        this.odooMaterialsOptions = [];
        this.currentBomItem = item;
        this.isAddMaterialModalOpen = true;
      },
      async handleSearchMaterial(query: string) {
        if (!query || query.length < 3) return;

        this.loadingMaterials = true;
        try {
          const response = await etcService.getOdooMaterials(query);
          this.odooMaterialsOptions = response
            .filter((material: any) => material.active)
            .map((material: any) => {
              const regex = /^\[(.*?)\]\s*(.*)$/;
              const match = material.display_name.match(regex);

              return {
                pn: match ? match[1] : '',
                description: match ? match[2] : '',
                odoo_id: material.id,
              };
            });
        } catch (error) {
          this.$log.showError(`Failed to fetch Odoo materials: ${error}`);
        } finally {
          this.loadingMaterials = false;
        }
      },
      confirmAddMaterial() {
        if (this.currentBomItem && this.selectedMaterial) {
          const material = this.odooMaterialsOptions.find((m) => m.pn === this.selectedMaterial);
          if (material) {
            this.currentBomItem.materials.push(material);
            this.currentBomItem.selectedMaterial = material.pn;
          }
        }
        this.isAddMaterialModalOpen = false;
        this.selectedMaterial = '';
      },
    },
  });
</script>

<style scoped>
  .no-data {
    padding-top: 62px;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }
  span > i.fa-trash:hover {
    cursor: pointer;
    color: red;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }

  :deep(.v-table tr:hover) {
    background-color: #f9f9f9;
  }
  :deep(.v-table tr) {
    height: 44px;
  }
  .top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
</style>
