<template>
  <v-row data-cy="tab-content-bom">
    <v-col cols="12" md="12" class="mt-4" v-if="viewBom && viewBom.length">
      <h2>Product info:</h2>

      <v-col cols="12" md="12" style="padding: 0px 12px">
        <v-text-field v-model="description" label="Description" variant="underlined"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" style="padding: 0px 12px">
        <v-select label="Routing" :items="routingList" v-model="selectedRouting" variant="underlined"></v-select>
      </v-col>
    </v-col>

    <v-col cols="12" md="12" style="padding: 0px 12px">
      <div v-if="viewBom && viewBom.length">
        <h2>BoM:</h2>
        <v-col cols="12" md="6">
          <v-text-field v-model="selectedReference" label="Reference" variant="underlined"></v-text-field>
        </v-col>
        <v-data-table :headers="headers" :items="viewBom" :items-per-page="25" class="elevation-1" density="compact">
          <template #item.materials="{ item }">
            <n-tooltip trigger="hover">
              <template #trigger>
                <span>{{ item.materials[0].pn }}</span>
              </template>
              {{ item.materials[0].description }}
            </n-tooltip>
          </template>

          <template #item.cutSize="{ item }">
            <span v-if="item.uom === 'Each' && item.cutSize == 0"></span>
            <span class="float-right" v-else>{{ item.cutSize.toFixed(2) }}</span>
          </template>
          <template #item.cutUom="{ item }">
            <span>{{ item.cutUom }}</span>
          </template>
          <template #item.cutQuantity="{ item }">
            <span v-if="item.uom === 'Each' && item.cutQuantity == 0"></span>
            <span class="float-right" v-else>{{ item.cutQuantity }}</span>
          </template>
          <template #item.quantity="{ item }">
            <span class="float-right">{{ item.quantity.toFixed(2) }}</span>
          </template>
          <template #item.uom="{ item }">
            <span>{{ item.uom }}</span>
          </template>
          <template #item.consumedOperation="{ item }">
            <span>{{ item.consumedOperation }}</span>
          </template>
        </v-data-table>

        <v-row>
          <v-col cols="12" md="12" class="d-flex align-center justify-end mt-4 mb-4">
            <span class="ml-2">Use Odoo v13</span>
            <v-checkbox v-model="legacyOdoo" class="mr-4" data-cy="create-in-odoo" hide-details></v-checkbox>
            <v-btn class="btn-orange" @click="createOdoo" data-cy="create-in-odoo">
              <span v-if="legacyOdoo === false">Create in Odoo v17</span>
              <span v-else>Create in Odoo v13</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-else class="text-center no-data" data-cy="details-content">
        <div v-if="!loadingData">
          <h2 data-cy="details-title">No Product Data</h2>
          <p data-cy="details-description">
            Please write
            <strong>PN</strong>
            and press
            <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
            button
          </p>
        </div>
        <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { defineComponent, PropType, version } from 'vue';
  import odooService from '@/services/odooService';

  export default defineComponent({
    name: 'GenerateViewBom',
    props: {
      viewBom: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      product_info: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      generation_item: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      loadingData: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        headers: [
          { title: 'Component', value: 'materials' },
          { title: 'Cut Size', value: 'cutSize' },
          { title: 'Cut UoM', value: 'cutUom' },
          { title: 'Cuts Num', value: 'cutQuantity' },
          { title: 'Quantity', value: 'quantity' },
          { title: 'UOM', value: 'uom' },
          { title: 'Consumed in', value: 'consumedOperation' },
        ],
        description: '' as string,
        selectedReference: '',
        routingList: ['Standard'],
        selectedRouting: 'Standard',
        legacyOdoo: false,
      };
    },
    computed: {},
    mounted() {
      this.populateReference();
      this.populateDescription();
    },
    methods: {
      populateReference() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.selectedReference = `Reference ${year}/${month}/${day}`;
      },
      populateDescription() {
        this.description = this.product_info || '';
      },
      async createOdoo() {
        //console.log('Create in Odoo');
        try {
          const data = {
            pn: this.generation_item,
            description: this.description,
            routing: this.selectedRouting,
            reference: this.selectedReference,
            lines: this.viewBom,
            legacyOdoo: this.legacyOdoo,
          };
          //  console.log(data);
          const response = await odooService.createInOdoo(data);
          const success = response?.result?.success;
          const product_id = response?.result?.product_id;
          const message = response?.result?.message;

          if (success) {
            this.showSuccessMessage('Successfully added');
            this.showOdooMessageWithLink(product_id, message);
          } else {
            this.$log.showError(`Failed to create BOM: ${message}`);
          }
        } catch (error: any) {
          this.$log.showError(`Failed to create: ${error.message || error}`);
        }
      },
      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },
      showOdooMessageWithLink(product_id: number, message: string) {
        const baseUrl = this.legacyOdoo ? 'https://staging.erp.viaphoton.com/web#id=' : 'https://erp17.viaphoton.dev/web#id=';

        const url = `${baseUrl}${product_id}&cids=1&menu_id=${this.legacyOdoo ? 142 : 285}&action=${this.legacyOdoo ? 283 : 436}&model=product.template&view_type=form`;

        const text = `
                      ${message} <br>
                      <a href="${url}" target="_blank">
                        Open in Odoo
                      </a>
                    `;

        this.$swal.fire({
          toast: true,
          position: 'top',
          icon: 'success',
          html: text,
          showConfirmButton: true,
          timer: 60000,
          timerProgressBar: true,
        });
      },
    },
  });
</script>

<style scoped>
  .no-data {
    padding-top: 62px;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
</style>
