<!-- src/views/assemblies/AssembliesEditBranchesModal.vue -->
<template>
  <v-dialog v-model="isModalOpen" max-width="1100px" persistent>
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">Edit Branches</span>
        <v-icon small @click="close" class="close-icon">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-table density="compact" class="branches-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Length (mm)</th>
              <th>Length (in)</th>
              <th>Jacket Color</th>
              <th>Label Color</th>
              <th>Label</th>
              <th>Leg</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(branch, index) in parsedBranches" :key="index">
              <td>{{ branch.id }}</td>
              <td>{{ branch.length }}</td>
              <td>{{ convertMmToInches(Number(branch.length)) }}</td>
              <td>{{ branch.jacketColor }}</td>
              <td>{{ branch.labelColor }}</td>
              <td>{{ branch.label }}</td>
              <td>{{ branch.leg }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="btn-cancel">Cancel</v-btn>
        <v-btn @click="save" class="btn-save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed } from 'vue';

  export default defineComponent({
    props: {
      branches: {
        type: Object as PropType<any>,
        required: true,
      },
      isModalOpen: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close', 'save'],

    methods: {
      close() {
        this.$emit('close');
      },
      save() {
        this.$emit('save', this.branches);
      },
      convertMmToInches(mm: number) {
        return (mm * 0.0393701).toFixed(2);
      },
    },
    computed: {
      parsedBranches() {
        const branchesArray: Array<{ id: string; length: string; jacketColor: string; labelColor: string; label: string; leg: string }> = [];

        // Рекурсивный обход дерева для получения всех ветвей
        interface Attribute {
          description: string;
          value: string;
        }

        const extractBranches = (node: any) => {
          if (node.children) {
            node.children.forEach((child: any) => extractBranches(child));
          } else {
            branchesArray.push({
              id: node.id,
              length: node.attributeValues?.find((attr: Attribute) => attr.description === 'FTube Length')?.value || 'N/A',
              jacketColor: node.attributeValues?.find((attr: Attribute) => attr.description === 'Color')?.value || 'N/A',
              labelColor: node.attributeValues?.find((attr: Attribute) => attr.description === 'Label Color')?.value || 'N/A',
              label: node.label,
              leg: node.name || 'N/A',
            });
          }
        };

        extractBranches(this.branches);
        return branchesArray;
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-icon {
    cursor: pointer;
  }
  .btn-orange {
    background-color: orange;
    color: white;
  }
  .branches-table {
    width: 100%;
    margin-top: 16px;
  }
</style>
